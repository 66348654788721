<template>
  <div class="container">
    <div class="d-flex flex-row bd-highlight">
      <div class="px-2 flex-fill bd-highlight">
        <h5>
          <b-badge variant="secondary" class="mr-1">
            {{ $getVisibleNames("mesh.career", false, "Programa") }}
            <!-- Carrera  --> </b-badge
          ><b-badge variant="light">{{
            career ? career.name : "Cargando..."
          }}</b-badge>
        </h5>
      </div>
      <div class="px-2 flex-fill bd-highlight">
        <h5>
          <b-badge variant="secondary" class="mr-1"
            >{{
              $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
            }}
          </b-badge>
          <b-badge variant="light">{{
            oldMatter ? oldMatter.name : "Cargando..."
          }}</b-badge>
          <b-dropdown size="sm" variant="light">
            <template v-if="matter">
              <b-dropdown-item
                :to="{
                  name: 'MatterProgramView',
                  params: { matter_id: matter_id },
                }"
              >
                {{ $getVisibleNames("mesh.career", false, "Programa") }}
                <!-- Programa -->
              </b-dropdown-item>
              <b-dropdown-item
                :to="{
                  name: 'PlanificationView',
                  params: { matter_id: matter_id },
                }"
              >
                {{
                  $getVisibleNames(
                    "teaching.mattersessionplanning",
                    false,
                    "Planificación de la Asignatura"
                  )
                }}</b-dropdown-item
              >
            </template>
          </b-dropdown>
        </h5>
      </div>
      <div class="px-2 flex-fill bd-highlight">
        <h5>
          <b-badge variant="secondary" class="mr-1"
            >{{ $getVisibleNames("mesh.shift", false, "Jornada") }} </b-badge
          ><b-badge variant="light">{{
            shift ? shift.name : "Cargando..."
          }}</b-badge>
        </h5>
      </div>
    </div>

    <div class="row" v-if="filteredSections.length > 0">
      <b-form-group
        :label="$getVisibleNames('teaching.section', false, 'Sección')"
        label-cols="0"
        label-cols-md="2"
        class="w-100 my-0 py-0"
      >
        <b-form-select
          :options="filteredSections"
          v-model="selected_section_id"
          value-field="id"
          text-field="label"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </div>
    <div class="row mt-1 w-100">
      <template v-if="selectedSection">
        <b-tabs content-class="mt-1" class="w-100" v-model="tab_index">
          <b-tab title="Evaluaciones Programadas">
            <Evaluations
              :Section="selectedSection"
              @selected="slotSelectedEvaluation"
              @selectedInstrument="slotSelectedInstrument"
            ></Evaluations>
          </b-tab>
          <b-tab title="Evaluación por Instrumentos">
            <Instrument
              :Section="selectedSection"
              :evaluation_id="selected_evaluation_to_qualify"
              :allows_crud="allows_crud"
              @changeEvaluationId="changeEvaluationId"
            ></Instrument>
          </b-tab>
          <b-tab title="Notas">
            <Qualifications
              :Section="selectedSection"
              :evaluation_id="selected_evaluation_to_qualify"
              :allows_crud="allows_crud"
            ></Qualifications>
          </b-tab>

          <b-tab title="Assessment">
            <Achievements
              :section_id="selectedSection.id"
              :allows_crud="allows_crud"
              :reload="tab_index == 3 ? true : false"
            ></Achievements>
          </b-tab>
        </b-tabs>
      </template>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    Evaluations: () => import("./Evaluations"),
    Instrument: () => import("./Instrument"),
    Qualifications: () => import("./Qualifications"),
    Achievements: () => import("./Achievements"),
  },
  data() {
    return {
      matter_id: Number(this.$route.params.matter_id),
      user: this.$store.getters.getUser,
      tab_index: Number(this.$route.params.tab_index),
      selected_section_id: Number(this.$route.params.section_id),
      selected_evaluation_to_qualify: Number(this.$route.params.evaluation_id),
      allows_crud: true,
    };
  },
  computed: {
    ...mapGetters({
      sections: names.SECTIONS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
      careers: names.CAREERS,
      periods: names.PERIODS,
      shifts: names.SHIFTS,
      institution: "getInstitution",
    }),
    matter() {
      if (!this.matter_id) return null;
      const matter = this.egress_profiles_matters.find(
        (x) => x.id == this.matter_id
      );
      return matter ? matter : null;
    },
    career() {
      if (!this.matter) return null;
      return this.careers.find((x) => x.id == this.matter.career);
    },
    oldMatter() {
      if (!this.matter) return null;
      const oldMatter = this.matters.find((x) => x.id == this.matter.matter);
      return oldMatter ? oldMatter : null;
    },
    shift() {
      if (!this.selectedSection) return null;
      return this.shifts.find((x) => x.id == this.selectedSection.shift);
    },
    // userSections() {
    //   if(!this.user)return[]
    //   let list = [];
    //   this.user.sections_as_professor.forEach((section_id) => {
    //     const section = this.sections.find((x) => x.id == section_id);
    //     if (section) list.push(section);
    //   });
    //   return list;
    // },
    filteredSections() {
      if (!this.matter && !this.institution && !this.user) return [];
      let list = [];
      this.user.sections_as_professor.forEach((section_id) => {
        const section = this.sections.find(
          (x) =>
            x.id == section_id && x.period == this.institution.actual_period.id
        );
        if (this.matter == null) return [];
        if (section && section.egress_profile_matter == this.matter.id) {
          let period = this.periods.find((x) => x.id == section.period);
          if (period) {
            section.label =
              section.name +
              String(
                " - " +
                  this.$getVisibleNames("mesh.period", false, "Período") +
                  ": "
              ) +
              period.name +
              " De: " +
              period.start_date +
              " al " +
              period.end_date;
            section.period_start_date = period.start_date;
          }
          list.push(section);
        }
      });
      return list.sort(function (a, b) {
        if (a.period_start_date < b.period_start_date) return 1;
        if (a.period_start_date > b.period_start_date) return -1;
        return 0;
      });
    },
    selectedSection() {
      if (this.selected_section_id)
        return this.filteredSections.find(
          (x) => x.id == this.selected_section_id
        );
      return null;
    },
  },
  methods: {
    slotSelectedEvaluation(evaluation_id) {
      this.selected_evaluation_to_qualify = evaluation_id;
      this.tab_index = 1;
    },
    slotSelectedInstrument(evaluation_id) {
      this.selected_evaluation_to_qualify = evaluation_id;
      this.tab_index = 2;
    },
    routerPush() {
      this.$router.push({
        name: "Dashboard",
        params: {
          matter_id: this.matter_id,
          tab_index: this.tab_index,
          section_id: this.selected_section_id,
          evaluation_id: this.selected_evaluation_to_qualify,
        },
      });
    },
    changeEvaluationId(evaluation_id) {
      this.selected_evaluation_to_qualify = evaluation_id;
    },
  },
  watch: {
    "$route.params.matter_id"() {
      this.selected_section_id = 0;
      this.matter_id = Number(this.$route.params.matter_id);
    },
    "$route.params.tab_index"() {
      this.tab_index = Number(this.$route.params.tab_index);
    },
    "$route.params.section_id"() {
      this.selected_section_id = Number(this.$route.params.section_id);
    },
    "$route.params.evaluation_id"() {
      this.selected_evaluation_to_qualify = Number(
        this.$route.params.evaluation_id
      );
    },
    selected_section_id: function () {
      this.routerPush();
    },
    tab_index: function () {
      this.routerPush();
    },
    selected_evaluation_to_qualify: function () {
      this.routerPush();
    },
    filteredSections() {
      if (this.filteredSections.length > 0) {
        this.selected_section_id = this.filteredSections[0].id;
      }
    },
  },
  created() {
    this.$store
      .dispatch(names.FETCH_USER, this.$store.state.user.id)
      .then((response) => {
        this.user.sections_as_professor = response.sections_as_professor;
      });
    this.$store.dispatch(names.FETCH_PERIODS);
    this.$store.dispatch(names.FETCH_SHIFTS);
    this.$store.dispatch(names.FETCH_EVALUATION_INSTRUMENTS);
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE_MATTER, this.matter_id)
      .then((response) => {
        this.$store.dispatch(names.FETCH_MATTER, response.matter);
        this.$store.dispatch(names.FETCH_CAREER, response.career);
        response.sections.forEach((section_id) => {
          this.$store.dispatch(names.FETCH_SECTION, section_id);
        });
      });
  },
};
</script>

<style>
</style>